import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  LinearProgress,
  useMediaQuery,
  CircularProgress
} from '@mui/material'

import { format, parseISO } from 'date-fns'
import {
  ShadowContainer,
  SubHeaderH3,
  LaakariButton
} from '../../../components/styled/uiComponents'

import PageHeader from '../../../components/PageHeader'

import FormSentConfirmation from '../FormSentConfirmation'
import { UserContext } from '../../../context'
import { AuthContext } from '../../../context/AuthProvider'
import { buildApiUrl } from '../../../utils/utils'
import theme from '../../../theme'
import {
  isFourthOrFifthYearStudent,
  isLicensedPhysician
} from '../../../utils/qualificationsChecks'
import ProductDetailDialog from './ProductDetailsDialog'
import { StampOrderForm } from './StampOrderForm'
import { StampDetails, StampKind } from './model'

type EventHandler = (...event: any[]) => void
type StampOrder = {
  stampDetails: StampDetails
  toBeSent: boolean
}

const MerchandiceForm = () => {
  const [formSent, setFormSent] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [activeChecboxName, setActiveCheckboxName] = useState<string>()

  const { user, setMerchState, merchState } = useContext(UserContext)
  const { bearerToken } = useContext(AuthContext)

  const navigate = useNavigate()

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [bachelorStampDetails, setBachelorStampDetails] = useState<StampOrder>()
  const [licensedPhysicianStampDetails, setlicensedPhysicianStampDetails] = useState<StampOrder>()
  const [paidStampDetails, setPaidStampDetails] = useState<StampOrder>()
  const [currentStampType, setCurrentStampType] = useState<StampKind>()

  const getOrderKey = (order: any) => {
    const isForShowingOnly =
      order.tietoniCategory === 'MULTI' || (order.tietoniId === 'MAGAZINE' && !order.isLatestOrder)

    return isForShowingOnly
      ? order.visibleName + (order.els_jasentuotteentilausid || '')
      : order.visibleName
  }

  const defaultValues = merchState
    ? merchState?.reduce((acc: any, cur: any) => ({ ...acc, [getOrderKey(cur)]: cur.inEffect }), {})
    : user?.memberProducts?.reduce(
        (acc: any, cur: any) => ({ ...acc, [getOrderKey(cur)]: cur.inEffect }),
        {}
      )

  const parseSubmitData = (data: any) => {
    const submitPayload: any = {
      els_hetu: user.els_hetu,
      orderStatuses: []
    }

    for (const [key, value] of Object.entries(data)) {
      const correctObject = user.memberProducts.findLast(
        (product: any) => product.visibleName === key
      )
      if (correctObject) {
        submitPayload.orderStatuses.push({ ...correctObject, inEffect: value })
      } else {
        // this means an old order for a multi-order product, so just ignore it
      }
    }

    return submitPayload
  }

  const { reset, control, handleSubmit, setValue } = useForm<any>({
    defaultValues
  })

  useEffect(() => {
    if (user) {
      reset(defaultValues)
    }
  }, [user])

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    for (const stampOrder of [
      bachelorStampDetails,
      licensedPhysicianStampDetails,
      paidStampDetails
    ]) {
      if (stampOrder?.toBeSent) {
        console.log(`Posting stamp order with data ${JSON.stringify(stampOrder.stampDetails)}`)
        await axios({
          method: 'post',
          url: buildApiUrl('stamp-order'),
          data: stampOrder.stampDetails,
          headers: {
            'x-tietoni-token': 'Bearer ' + bearerToken
          }
        })
      }
    }
    const res = await axios({
      method: 'put',
      url: buildApiUrl('member'),
      data: parseSubmitData(data),
      headers: {
        'x-tietoni-token': 'Bearer ' + bearerToken
      }
    })
    console.log(res.data)
    setIsLoading(false)
    setMerchState(res.data)
    setFormSent(true)
  }

  const handleNavigateBack = () => {
    navigate('/#merch')
  }

  const handleCloseDialog = () => {
    setActiveCheckboxName(undefined)
    setCurrentStampType(undefined)
  }

  const confirmStampDetails = (stampKind: StampKind, stampDetails: StampDetails) => {
    console.log(`Confirming stamp details for ${stampKind}:`, stampDetails)
    const stampOrder: StampOrder = { stampDetails, toBeSent: true }
    switch (stampKind) {
      case 'BACHELOR_STAMP':
        setBachelorStampDetails(stampOrder)
        break
      case 'LICENSED_PHYSICIAN_STAMP':
        setlicensedPhysicianStampDetails(stampOrder)
        break
      case 'PAID_STAMP':
        setPaidStampDetails(stampOrder)
    }
    handleCloseDialog()
    if (activeChecboxName) {
      setValue(activeChecboxName, true)
      setActiveCheckboxName(undefined)
    }
  }

  const formatLabel = (product: any) => {
    if (product.tietoniId === 'MAGAZINE' && product.els_tilauspaiva && !product.els_peruutuspaiva) {
      const orderDate = parseISO(product.els_tilauspaiva)
      const formattedOrderDate = format(orderDate, 'dd.MM.yyyy')
      return `${product.visibleName} (Tilauspäivä: ${formattedOrderDate})`
    } else if (product.tietoniId === 'MAGAZINE' && product.els_peruutuspaiva) {
      const orderDate = parseISO(product.els_tilauspaiva)
      const formattedOrderDate = format(orderDate, 'dd.MM.yyyy')
      const cancelDate = parseISO(product.els_peruutuspaiva)
      const formattedCancelDate = format(cancelDate, 'dd.MM.yyyy')
      return `${product.visibleName} (Tilauspäivä: ${formattedOrderDate}) (Peruutuspäivä: ${formattedCancelDate})`
    }

    return product.visibleName
  }

  const interceptOnChange = (
    fieldName: string,
    tietoniId: string,
    onChange: EventHandler,
    ...event: any[]
  ) => {
    const notToBeSent = (order?: StampOrder) => order && { ...order, toBeSent: false }
    if (event[1] === false) {
      // checkbox unchecked
      switch (tietoniId) {
        case 'BACHELOR_STAMP':
          setBachelorStampDetails(notToBeSent)
          break
        case 'LICENSED_PHYSICIAN_STAMP':
          setlicensedPhysicianStampDetails(notToBeSent)
          break
        case 'PAID_STAMP':
          setPaidStampDetails(notToBeSent)
          break
      }
      onChange(...event)
    } else {
      // checkboxChecked
      switch (tietoniId) {
        case 'BACHELOR_STAMP':
        case 'LICENSED_PHYSICIAN_STAMP':
        case 'PAID_STAMP':
          setActiveCheckboxName(fieldName)
          setCurrentStampType(tietoniId)
          break
        default:
          onChange(...event)
      }
    }
  }

  const hasRightToProduct = (tietoniId: string) => {
    switch (tietoniId) {
      case 'BACHELOR_STAMP':
        return isFourthOrFifthYearStudent(user)
      case 'LICENSED_PHYSICIAN_STAMP':
      case 'PAID_STAMP':
        return isLicensedPhysician(user)
      default:
        return true
    }
  }

  if (user) {
    return (
      <>
        {formSent ? (
          <FormSentConfirmation
            formName='Jäsentuotteet'
            longInfo='Jäsentuotteiden tilaustiedot on muutettu'
            subUrl='#merch'
          />
        ) : (
          <>
            <PageHeader />
            <div style={{ padding: '3vh' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h3' color='primary'>
                    Jäsentuotteet
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <p>
                    Lisätietoja jäsentuotteista löytyy osoitteesta:{' '}
                    <a
                      href='https://www.laakariliitto.fi/palvelut/jasentuotteet/'
                      target='_blank'
                      rel='noreferrer'>
                      laakariliitto.fi
                    </a>
                  </p>

                  <p>
                    Osa tuotteista on kertaluonteisia, jolloin niitä ei voi tilata useampaan
                    kertaan. Voimassa olevat toistuvat tuotteet on rastitettu.{' '}
                  </p>
                  <p>
                    Toistuvan tuotteen peruminen tapahtuu poistamalla tuotteen rasti ja klikkaamalla
                    Lähetä painiketta.
                  </p>
                </Grid>
                <Grid item xs={12} md={8}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ShadowContainer>
                      <SubHeaderH3>Tilatut jäsentuotteet</SubHeaderH3>
                      {merchState
                        ? merchState?.map((product: any) => (
                            <div key={getOrderKey(product)}>
                              <Controller
                                name={getOrderKey(product)}
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                  <FormControlLabel
                                    label={formatLabel(product)}
                                    control={
                                      <Checkbox
                                        disabled={
                                          !product.cancellable ||
                                          !hasRightToProduct(product.tietoniId) ||
                                          ('isLatestOrder' in product
                                            ? !product.isLatestOrder
                                            : false) ||
                                          isLoading
                                        }
                                        value={value}
                                        checked={value}
                                        onChange={(...event: any[]) =>
                                          interceptOnChange(
                                            name,
                                            product.tietoniId,
                                            onChange,
                                            ...event
                                          )
                                        }
                                        sx={{
                                          color: '#00624b',
                                          '&.Mui-disabled': {
                                            color: 'grey'
                                          }
                                        }}
                                      />
                                    }
                                  />
                                )}
                              />
                            </div>
                          ))
                        : user?.memberProducts?.map((product: any) => (
                            <div key={getOrderKey(product)}>
                              <Controller
                                name={getOrderKey(product)}
                                control={control}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <FormControlLabel
                                      label={formatLabel(product)}
                                      control={
                                        <Checkbox
                                          disabled={
                                            !product.cancellable ||
                                            !hasRightToProduct(product.tietoniId) ||
                                            ('isLatestOrder' in product
                                              ? !product.isLatestOrder
                                              : false) ||
                                            isLoading
                                          }
                                          value={value}
                                          checked={value}
                                          onChange={(...event: any[]) =>
                                            interceptOnChange(
                                              name,
                                              product.tietoniId,
                                              onChange,
                                              ...event
                                            )
                                          }
                                          sx={{
                                            color: '#00624b',
                                            '&.Mui-disabled': {
                                              color: 'grey'
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  )
                                }}
                              />
                            </div>
                          ))}
                    </ShadowContainer>
                    <Grid
                      container
                      spacing={3}
                      direction='row'
                      alignItems='center'
                      wrap='wrap-reverse'
                      justifyContent='space-between'
                      sx={{ paddingTop: '3vh' }}>
                      <Grid item xs={12} md={4}>
                        <LaakariButton variant='outlined' onClick={handleNavigateBack}>
                          Palaa etusivulle
                        </LaakariButton>
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ textAlign: { md: 'right' } }}>
                        <LaakariButton type='submit' value='Lähetä' disabled={isLoading}>
                          {isLoading ? <CircularProgress size={24.5} /> : 'Lähetä'}
                        </LaakariButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
            <Grid container maxWidth={'100%'}>
              <Grid item xs={12}>
                <ProductDetailDialog
                  fullScreen={fullScreen}
                  open={currentStampType === 'BACHELOR_STAMP'}
                  title={activeChecboxName || 'Tilaa leimasin'}
                  content={
                    <StampOrderForm
                      stampKind={'BACHELOR_STAMP'}
                      closeDialog={handleCloseDialog}
                      confirmDetails={details => confirmStampDetails('BACHELOR_STAMP', details)}
                      stampDetails={bachelorStampDetails?.stampDetails}
                    />
                  }
                  closeDialog={handleCloseDialog}
                />
                <ProductDetailDialog
                  fullScreen={fullScreen}
                  open={currentStampType === 'LICENSED_PHYSICIAN_STAMP'}
                  title={activeChecboxName || 'Tilaa leimasin'}
                  content={
                    <StampOrderForm
                      stampKind={'LICENSED_PHYSICIAN_STAMP'}
                      closeDialog={handleCloseDialog}
                      confirmDetails={details =>
                        confirmStampDetails('LICENSED_PHYSICIAN_STAMP', details)
                      }
                      stampDetails={licensedPhysicianStampDetails?.stampDetails}
                    />
                  }
                  closeDialog={handleCloseDialog}
                />
                <ProductDetailDialog
                  fullScreen={fullScreen}
                  open={currentStampType === 'PAID_STAMP'}
                  title={activeChecboxName || 'Tilaa leimasin'}
                  content={
                    <StampOrderForm
                      stampKind={'PAID_STAMP'}
                      closeDialog={handleCloseDialog}
                      confirmDetails={details => confirmStampDetails('PAID_STAMP', details)}
                      stampDetails={paidStampDetails?.stampDetails}
                    />
                  }
                  closeDialog={handleCloseDialog}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  } else {
    return (
      <div>
        <PageHeader />
        <LinearProgress />
      </div>
    )
  }
}

export default MerchandiceForm
