import React, { useContext, useState } from 'react'

import { Grid, useMediaQuery } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import {
  ContainerHeaderH1,
  InfoContainer,
  LaakariButton,
  ShadowTableRow
} from '../../components/styled/uiComponents'
import { MembershipContext, UserContext } from '../../context'
import { parseDate } from '../../utils/utils'
import {
  isCurrentAnyMemberOfFMA,
  isCurrentProperMemberOfFMA,
  isStudentInvite,
  showStudentMembershipApplication,
  userFoundInAapeli
} from '../../utils/membershipChecks'
import Divider from './Divider'
import MobileTitle from './MobileTitle'

const MembershipInfo = () => {
  const { user } = useContext(UserContext)
  const { setContextMembership } = useContext(MembershipContext)
  const [isOpen, setIsOpen] = useState(!isMobile)
  const navigate = useNavigate()
  const memberships = user?.memberships
  const theme = useTheme()

  const largeScreen = useMediaQuery(theme.breakpoints.down('md'))
  const mediumScreen = useMediaQuery(theme.breakpoints.down('xl'))

  const isProperMember = isCurrentProperMemberOfFMA(user)
  const isAnyMember = isCurrentAnyMemberOfFMA(user)
  const studentInvite = isStudentInvite()
  const studentMemberApplication = showStudentMembershipApplication(user)
  const userInAapeli = userFoundInAapeli(user)

  const handleNavigateForm = (membership: any) => {
    setContextMembership(membership)
    navigate('/memberAction')
  }

  // reason texts for membership ending
  const reasonMap: { [key: string]: string } = {
    861120000: 'Eronnut',
    861120004: 'Eronnut, tiedoksi hallitukselle',
    861120001: 'Erotettu',
    861120002: 'Kuollut',
    861120003: 'Jäsenmaksun vaihtuminen'
  }

  // find active membership
  const activeMembership = memberships?.find(
    (membership: any) =>
      !membership?.els_jasenloppu && membership?.yhdistys_name?.includes('Suomen Lääkäriliitto')
  )

  // active membership parameters for action buttons
  const activeMembershipParams = {
    els_jasentyyppi: activeMembership?.els_jasentyyppi3_els_jasentyyppi,
    els_liitonjasenyysid: activeMembership?.els_liitonjasenyysid,
    yhdistys_name: activeMembership?.yhdistys_name
  }

  const actionButtons = () =>
    isMobile ? (
      <>
        <Grid item xs={12} md={4} style={{ margin: 4, textAlign: 'center' }}>
          {(!isProperMember || !userInAapeli) && (
            <LaakariButton onClick={() => navigate('/membershipApplication')}>
              Liity jäseneksi
            </LaakariButton>
          )}
          {(studentMemberApplication || !userInAapeli) && (
            <LaakariButton onClick={() => navigate('/studentApplication')}>
              Liity Lääkäriliiton opiskelijajäseneksi
            </LaakariButton>
          )}
          {isProperMember && (
            <>
              <Grid item xs={12} md={4} style={{ marginBottom: 4, textAlign: 'center' }}>
                <LaakariButton
                  style={{ marginBottom: 4, whiteSpace: 'nowrap' }}
                  onClick={() =>
                    handleNavigateForm({ ...activeMembershipParams, actionType: '0' })
                  }>
                  Alennushakemus
                </LaakariButton>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: 4, textAlign: 'center' }}>
                <LaakariButton
                  style={{ marginBottom: 4, whiteSpace: 'nowrap' }}
                  onClick={() =>
                    handleNavigateForm({ ...activeMembershipParams, actionType: '2' })
                  }>
                  Eroilmoitus
                </LaakariButton>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: 4, textAlign: 'center' }}>
                <LaakariButton
                  style={{ marginBottom: 4, whiteSpace: 'nowrap' }}
                  onClick={() =>
                    handleNavigateForm({ ...activeMembershipParams, actionType: '1' })
                  }>
                  Tilinumeron ilmoitus
                </LaakariButton>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4} style={{ margin: 4, textAlign: 'center' }}>
          {!isAnyMember && studentInvite && (
            <LaakariButton onClick={() => navigate('/studentForm')} sx={{ minWidth: '3vh' }}>
              Ilmoittaudu opiskelijaksi
            </LaakariButton>
          )}
        </Grid>
      </>
    ) : (
      <Grid
        container
        spacing={1}
        direction={largeScreen || isProperMember ? 'row' : 'row-reverse'}
        justifyContent={largeScreen ? 'flex-start' : undefined}
        item
        xs={12}
        style={mediumScreen ? { marginLeft: '-3vw' } : undefined}>
        {!isAnyMember && studentInvite && (
          <Grid item md={7}>
            <LaakariButton onClick={() => navigate('/studentForm')} sx={{ minWidth: '3vh' }}>
              Ilmoittaudu opiskelijaksi
            </LaakariButton>
          </Grid>
        )}
        {(studentMemberApplication || !userInAapeli) && (
          <Grid item md={5}>
            <LaakariButton onClick={() => navigate('/studentApplication')} sx={{ minWidth: '3vh' }}>
              Liity Lääkäriliiton opiskelijajäseneksi
            </LaakariButton>
          </Grid>
        )}
        {!isProperMember || !userInAapeli ? (
          <Grid item md={5}>
            <LaakariButton
              style={{ marginRight: 4 }}
              onClick={() => navigate('/membershipApplication')}>
              Liity jäseneksi
            </LaakariButton>
          </Grid>
        ) : (
          <>
            <Grid item md={4}>
              <LaakariButton
                onClick={() => handleNavigateForm({ ...activeMembershipParams, actionType: '0' })}>
                Alennushakemus
              </LaakariButton>
            </Grid>
            <Grid item md={4}>
              <LaakariButton
                onClick={() => handleNavigateForm({ ...activeMembershipParams, actionType: '2' })}>
                Eroilmoitus
              </LaakariButton>
            </Grid>
            <Grid item md={4}>
              <LaakariButton
                style={{ marginRight: 4, whiteSpace: 'nowrap' }}
                onClick={() => handleNavigateForm({ ...activeMembershipParams, actionType: '1' })}>
                Tilinumeron ilmoitus
              </LaakariButton>
            </Grid>
          </>
        )}
      </Grid>
    )

  return (
    <>
      <Divider id='membership' isMobile={isMobile} />
      <InfoContainer>
        <Grid container>
          <Grid container alignItems='center'>
            {isMobile ? (
              <MobileTitle isOpen={isOpen} setIsOpen={setIsOpen} title='Jäsenyydet' />
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <ContainerHeaderH1>Jäsenyydet</ContainerHeaderH1>
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingBottom: { xs: '3vh' } }}>
                  {actionButtons()}
                </Grid>
              </>
            )}
          </Grid>
          {isOpen && (
            <>
              {isMobile && actionButtons()}
              {memberships?.map((membership: any, i: number) => (
                <ShadowTableRow key={i}>
                  <Grid container direction={isMobile ? 'column' : 'row'}>
                    <Grid item xs={10}>
                      <Grid container direction='column'>
                        <Grid item xs={4}>
                          <Grid container direction={isMobile ? 'column' : 'row'}>
                            <Grid
                              item
                              xs={12}
                              md={
                                membership?.els_jasenloppu || membership?.els_paattymisensyy ? 2 : 3
                              }>
                              <h3>Organisaatio</h3>
                              <p>{membership?.yhdistys_name}</p>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={
                                membership?.els_jasenloppu || membership?.els_paattymisensyy ? 2 : 3
                              }>
                              <h3>Jäsenlaji</h3>
                              <p>{membership?.els_jasentyyppi3_els_jasentyyppi}</p>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={
                                membership?.els_jasenloppu || membership?.els_paattymisensyy ? 2 : 3
                              }>
                              <h3>Alkanut</h3>
                              <p>{parseDate(membership?.els_jasenalkupvm)}</p>
                            </Grid>
                            {membership?.els_jasenloppu && (
                              <Grid item xs={12} md={2}>
                                <h3>Päättynyt</h3>
                                <p>{parseDate(membership?.els_jasenloppu)}</p>
                              </Grid>
                            )}
                            {membership?.els_paattymisensyy && (
                              <Grid item xs={12} md={2}>
                                <h3>Päättymisen syy</h3>
                                <p>{reasonMap[membership?.els_paattymisensyy]}</p>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Grid container direction='row'>
                            <Grid item xs={12} md={3}>
                              <p>
                                Jäsenmaksu: <b>{membership?.jasenmaksu?.els_summa} €</b>
                              </p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {/* TODO: Maksettu summa tähän */}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Grid container>
                            <Grid item xs={12}>
                              {membership?.els_alennus5_els_alennuksennimi ? (
                                <p>
                                  Alennus: <b>{membership?.els_alennus5_els_alennuksennimi}</b>
                                </p>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Grid container direction='row'>
                        {!membership?.els_jasenloppu &&
                          membership?.yhdistys_name?.includes('Suomen Lääkäriliitto') && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                margin: 'auto',
                                width: '100%',
                                padding: '10px',
                                paddingTop: '10vh'
                              }}>
                              {/* <LaakariButton
                                variant='outlined'
                                onClick={() =>
                                  handleNavigateForm({
                                    els_jasentyyppi: membership?.els_jasentyyppi3_els_jasentyyppi,
                                    els_liitonjasenyysid: membership?.els_liitonjasenyysid,
                                    yhdistys_name: membership?.yhdistys_name
                                  })
                                }>
                                Alennus tai&nbsp;ilmoitus
                              </LaakariButton> */}
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ShadowTableRow>
              ))}
            </>
          )}
        </Grid>
      </InfoContainer>
    </>
  )
}

export default MembershipInfo
