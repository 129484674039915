import React, { useState, useContext } from 'react'
import { Divider, Drawer, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useAuth } from '../context/AuthProvider'
import { UserContext } from '../context'
import {
  isCurrentAnyMemberOfFMAOrOtherOrganization,
  isStudentMember
} from '../utils/membershipChecks'
import laakariLogo from '../icons/logoSmall.svg'
import FrontPageConfirmation from './FrontPageConfirmation'
import LogoutConfirmation from './LogoutConfirmation'
import LinkWithArrow from './LinkWithArrow'
import { LaakariButton, HeaderLinkLabel } from './styled/uiComponents'

interface Props {
  buttonsHidden?: boolean
}

const PageHeader: React.FC<Props> = ({ buttonsHidden }) => {
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [navigateBackOpen, setNavigateBackOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const location = useLocation()
  const auth = useAuth()

  const { user } = useContext(UserContext)
  const isMember = isCurrentAnyMemberOfFMAOrOtherOrganization(user)
  const studentMember = isStudentMember(user)

  const currentLocation = location.pathname

  const locationFrontPage = currentLocation === '/'
  const isLoggedIn = auth.bearerToken ? true : false

  const handleClickOpenLogout = () => {
    setLogoutOpen(true)
  }

  const handleCloseLogout = () => {
    setLogoutOpen(false)
  }

  const handleClickOpenNavigateBack = () => {
    setNavigateBackOpen(true)
  }

  const handleCloseNavigateBack = () => {
    setNavigateBackOpen(false)
  }

  const pages = [
    { label: 'Muuta yhteystietojasi', linkTo: '/#info' },
    { label: 'Päivitä työpaikkatietojasi', linkTo: '/#work' },
    { label: 'Ilmoittaudu opiskelijaksi', linkTo: '/#membership', show: !studentMember },
    { label: 'Jäsenyydet ja jäsenmaksualennukset', linkTo: '/#membership' },
    { label: 'Jäsentuotteet ja Lääkärilehti', linkTo: '/#merch' }
  ].filter(page => page.show === undefined || page.show)

  const menuItems = (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      {locationFrontPage &&
        pages.map(({ label, linkTo }) => {
          if (isMember || studentMember) {
            return (
              <Grid key={label} item xs={2} sx={{ paddingTop: '2vh' }}>
                <Link to={linkTo} smooth style={{ textDecoration: 'none' }}>
                  <HeaderLinkLabel>{label}</HeaderLinkLabel>
                </Link>
              </Grid>
            )
          }
        })}
    </Grid>
  )

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <LinkWithArrow handleClick={handleClickOpenLogout} label='Kirjaudu ulos' />
        <Divider />
        {!locationFrontPage && (
          <ListItem onClick={handleClickOpenNavigateBack} style={{ textDecoration: 'none' }}>
            <ListItemText sx={{ color: 'black' }}>Etusivu</ListItemText>
          </ListItem>
        )}
        <Divider />
        {locationFrontPage &&
          pages.map(item => (
            <ListItem key={item.label}>
              <Link to={item.linkTo} smooth style={{ textDecoration: 'none' }}>
                <ListItemText sx={{ color: 'black' }}>{item.label}</ListItemText>
              </Link>
            </ListItem>
          ))}
      </List>
    </Box>
  )

  const responsiveBar = (
    <AppBar sx={{ bgcolor: 'white' }} position='static'>
      <>
        <Toolbar>
          <Grid
            container
            direction='row'
            alignItems='center'
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container direction='row' alignItems='center' item xs={10}>
              <Grid item xs={3} md={1}>
                <img src={laakariLogo} alt='LOGO' />
              </Grid>
              <Grid item xs={9} md={11}>
                <Typography color='primary' variant='h2'>
                  Tietoni
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction='row' alignItems='center' item xs={2}>
              {!buttonsHidden && (
                <Grid item xs={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'flex' } }}>
                  {isLoggedIn && !locationFrontPage ? (
                    <LaakariButton onClick={handleClickOpenNavigateBack}>Etusivu</LaakariButton>
                  ) : null}
                </Grid>
              )}
              <Grid item xs={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'flex' } }}>
                {isLoggedIn && (
                  <LinkWithArrow handleClick={handleClickOpenLogout} label='Kirjaudu ulos' />
                )}
              </Grid>
              <Grid item xs={4} sx={{ color: 'black', display: { xs: 'flex', md: 'none' } }}>
                <IconButton size='large' onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor='right'
                  variant='temporary'
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: 'block', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 }
                  }}>
                  {drawer}
                </Drawer>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </>
      {isLoggedIn && (
        <Grid container>
          <Grid item xs={12} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <div
              style={{
                backgroundColor: '#00624B',
                paddingLeft: '3vh',
                paddingBottom: '1vh',
                height: '100%',
                width: '100%'
              }}>
              {menuItems}
            </div>
          </Grid>
          <Grid item xs={12} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <div
              style={{
                backgroundColor: '#00624B',
                height: '3vh',
                width: '100%',
                paddingLeft: '3vh'
              }}></div>
          </Grid>
        </Grid>
      )}
    </AppBar>
  )

  return (
    <>
      {responsiveBar}
      {logoutOpen && <LogoutConfirmation open={logoutOpen} handleClose={handleCloseLogout} />}
      {navigateBackOpen && (
        <FrontPageConfirmation open={navigateBackOpen} handleClose={handleCloseNavigateBack} />
      )}
    </>
  )
}

export default PageHeader
