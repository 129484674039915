import React, { useContext } from 'react'
import { LinearProgress } from '@mui/material'
import PageHeader from '../../components/PageHeader'

import { UserContext } from '../../context'
import {
  isCurrentAnyMemberOfFMAOrOtherOrganization,
  isStudentMember
} from '../../utils/membershipChecks'
import MainInfo from './MainInfo'
import MembershipInfo from './MembershipInfo'
import Merchandice from './Merchandice'
import PersonalInfo from './PersonalInfo'
import WorkInfo from './WorkInfo'
import Qualifications from './Qualifications'
import Applications from './Applications'

const LandingPage = () => {
  const applications = true
  const { user } = useContext(UserContext)
  const userIsCurrentMember = isCurrentAnyMemberOfFMAOrOtherOrganization(user)
  const userIsStudentMember = isStudentMember(user)

  return user ? (
    <>
      <PageHeader />
      <MainInfo />
      {(userIsCurrentMember || userIsStudentMember) && <PersonalInfo />}
      {applications && <Applications />}
      {(userIsCurrentMember || userIsStudentMember) && <Qualifications />}
      {(userIsCurrentMember || userIsStudentMember) && <WorkInfo />}
      <MembershipInfo />
      {(userIsCurrentMember || userIsStudentMember) && <Merchandice />}
    </>
  ) : (
    <LinearProgress />
  )
}

export default LandingPage
