import { getInviteHashLocalStorage } from '../utils/localStorage'

const FMA = 'Suomen Lääkäriliitto'
const studentMemberKind = 'Opiskelija, ei jäsen'
const targetOrganizations = [FMA] // other could be added here when they have agreed to use this service for applications

const isCurrentMember = (user: any, filter?: (membership: any) => boolean) => {
  if (!user) {
    return false
  }
  const currentDate = new Date().toISOString()
  const currentMembership = user.memberships.find(
    (m: any) =>
      targetOrganizations.includes(m.yhdistys_name) &&
      m.els_jasenalkupvm &&
      m.els_jasenalkupvm < currentDate &&
      (!m.els_jasenloppu || m.els_jasenloppu > currentDate) &&
      (!filter || filter(m))
  )
  return currentMembership != undefined
}

// to show or hide student application button
export const isCurrentAnyMemberOfFMA = (user: any) =>
  isCurrentMember(user, membership => membership.yhdistys_name === FMA)

// to show or hide member application button
export const isCurrentProperMemberOfFMA = (user: any) =>
  isCurrentMember(
    user,
    membership =>
      membership.yhdistys_name === FMA &&
      !membership.els_jasentyyppi3_els_jasentyyppi.endsWith(studentMemberKind)
  )

// to show or hide menu links
export const isCurrentAnyMemberOfFMAOrOtherOrganization = (user: any) =>
  isCurrentMember(user, membership => targetOrganizations.includes(membership.yhdistys_name))

export const userFoundInAapeli = (user: any) => user?.els_henkilonumero != undefined

// check if there is an invite hash in local storage
export const isStudentInvite = (): boolean => {
  return getInviteHashLocalStorage() !== null
}

// check student membership status from sidosryhmat
export const isStudentMember = (user: any): boolean => {
  if (!user || !user.sidosryhmat) {
    return false
  }

  const studentMembership = user.sidosryhmat.find((sidosryhma: any) => {
    // if els_paataso is "Lääketieteen opiskelijat"
    const isStudentGroup = sidosryhma['paataso.els_paataso'] === 'Lääketieteen opiskelijat'
    // if there is no end date or it is in the future
    const hasNoEndDateOrInFuture =
      !sidosryhma['els_paattymispaiva'] || new Date(sidosryhma['els_paattymispaiva']) > new Date()
    return isStudentGroup && hasNoEndDateOrInFuture
  })

  return !!studentMembership
}

export const showStudentMembershipApplication = (user: any): boolean => {
  if (!user || !user.sidosryhmat || !isStudentMember(user)) {
    return false
  }

  // Calculate date 3 years and 4 months (40 months) ago
  const now = new Date()
  const minStartDate = new Date(now)
  minStartDate.setMonth(minStartDate.getMonth() - 40)

  // Check if student status started early enough
  const hasValidStartDate = user.sidosryhmat.find(
    (sidosryhma: any) =>
      sidosryhma['paataso.els_paataso'] === 'Lääketieteen opiskelijat' &&
      new Date(sidosryhma['els_alkupaiva']) <= minStartDate
  )

  // Check that user has no current or past FMA membership
  const hasNoFMAMembership = !user.memberships?.some((m: any) => m.yhdistys_name === FMA)

  return hasValidStartDate && hasNoFMAMembership
}
