import React, { createContext, useState } from 'react'
import { Job } from '../types/job'

export const UserContext = createContext<any>(null)

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null)
  const [merchState, setMerchState] = useState()

  const currentJob = React.useMemo(() => {
    const employments = user?.employments

    const currentEmployments: Job[] = employments
      ?.filter((job: Job) => !job.els_loppupvm)
      .sort(
        (a: Job, b: Job) => new Date(b.els_alkupvm).getTime() - new Date(a.els_alkupvm).getTime()
      )

    const previousEmployments: Job[] = employments
      ?.filter((job: Job) => job.els_loppupvm)
      .sort(
        (a: Job, b: Job) => new Date(b.els_alkupvm).getTime() - new Date(a.els_alkupvm).getTime()
      )

    return (
      currentEmployments?.find((job: Job) => job.els_paatoimi === true) ||
      currentEmployments?.find((job: Job) => job.els_paatoimi === false) ||
      previousEmployments?.[0]
    )
  }, [user])

  const value = { user, setUser, merchState, setMerchState, currentJob }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
