import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import axios from 'axios'
import { UserContext, MetadataContext } from '../../../context'
import { AuthContext } from '../../../context/AuthProvider'
import PageHeader from '../../../components/PageHeader'
import { LaakariButton, ShadowContainer } from '../../../components/styled/uiComponents'
import FormButtonContainer from '../../../components/FormButtonContainer'
import FormSentConfirmation from '../FormSentConfirmation'
import FormStepper from '../../../components/FormStepper'
import { isSessionInvalidError } from '../../../utils/error'
import { buildApiUrl, parseFormData } from '../../../utils/utils'
import { putInviteHashLocalStorage } from '../../../utils/localStorage'
import Summary from './Summary'
import PersonalInfo from './PersonalInfo'
import { StudentApplicationFormSchema } from './schema'

const StudentApplicationForm = () => {
  const [page, setPage] = useState(0)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const [student, setStudent] = useState(false)
  const [studyStart, setStudyStart] = useState('')

  const { user } = useContext(UserContext)
  const { metaData } = useContext(MetadataContext)
  const { bearerToken, handleLogout } = useContext(AuthContext)
  const navigate = useNavigate()

  const currentValidationSchema = StudentApplicationFormSchema[page]

  const methods = useForm<any>({
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
    defaultValues: {
      formType: 'STUDENTMEMBER',
      organization: {
        name: 'Suomen Lääkäriliitto'
      },
      els_hetu: user?.els_hetu,
      birthdate: user?.birthdate,
      lastname: user?.lastname,
      firstname: user?.firstname,
      nickname: user?.nickname
    }
  })

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger
  } = methods

  const handleNext = async (e: any) => {
    e.preventDefault()
    const isStepValid = await trigger()
    if (isStepValid) {
      setPage(prevActivePage => prevActivePage + 1)
    }

    // set these separately as they are not part of the form values for some reason
    setValue('lastname', user?.lastname)
    setValue('firstname', user?.firstname)
    setValue('els_hetu', user?.els_hetu)
    setValue('birthdate', user?.birthdate)
  }

  const handleBack = (e: any) => {
    e.preventDefault()
    setPage(prevActivePage => prevActivePage - 1)
  }

  const onSubmit = async (data: any) => {
    // remove items from data that would cause validation errors
    delete data.study_start
    delete data.membership_starts

    try {
      const parsedData = parseFormData(data)
      const url = buildApiUrl('membership-application')
      await axios.post(url, parsedData, {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      setFormSubmitted(true)
    } catch (error) {
      toast.error('Virhe lomakkeen lähetyksessä. Yritä uudelleen.')
      console.log('error when submitting form', error)
      if (isSessionInvalidError(error)) {
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  const handleStudentFormClick = () => {
    // generate a random hash to access student form
    const randomHash =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    putInviteHashLocalStorage(randomHash)
    navigate('/studentForm')
  }

  // if "doctor" is selected on page 1, redirect to membership application
  useEffect(() => {
    if (page === 1 && !student) {
      navigate('/membershipApplication')
    }
  }, [page, student, navigate])

  // user can apply for a student membership if they started their studies more than 3 years and 4 months ago
  const threeYearsAndFourMonthsAgo = new Date()
  threeYearsAndFourMonthsAgo.setMonth(threeYearsAndFourMonthsAgo.getMonth() - 40)

  const canApply = new Date(studyStart).getTime() <= threeYearsAndFourMonthsAgo.getTime()

  const pageProps = { register, user, errors, metaData, getValues, control }

  return metaData && user ? (
    <Fragment>
      {formSubmitted ? (
        <FormSentConfirmation formName='Liity Lääkäriliiton jäseneksi' />
      ) : (
        <Fragment>
          <PageHeader />
          <div style={{ padding: '3vh' }}>
            <Typography variant='h3' color='primary' sx={{ paddingBottom: '3vh' }}>
              Liity opiskelijajäseneksi
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <FormStepper activeStep={page} stepsCount={4} />
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ShadowContainer>
                      {page === 0 && (
                        <>
                          <Typography variant='h4' color='primary'>
                            Oletko lääkäri vai opiskelija?
                          </Typography>
                          <p>
                            Jos olet valmistunut lääkäriksi ETA-alueen ulkopuolelta etkä saanut
                            vielä Valviran laillistusta, olethan yhteydessä{' '}
                            <a href='mailto:laakariliitto@laakariliitto.fi'>
                              laakariliitto@laakariliitto.fi
                            </a>
                            .
                          </p>
                          <Grid container spacing={3} direction={{ xs: 'column', md: 'row' }}>
                            <Grid item xs={12}>
                              <FormControl>
                                <FormLabel>Valitse jäsentyyppi</FormLabel>
                                <RadioGroup
                                  row
                                  value={student ? 'student' : 'doctor'}
                                  onChange={e => setStudent(e.target.value === 'student')}>
                                  <FormControlLabel
                                    value='doctor'
                                    control={<Radio />}
                                    label='Lääkäri'
                                  />
                                  <FormControlLabel
                                    value='student'
                                    control={<Radio />}
                                    label='Opiskelija'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {page === 1 && student && (
                        <>
                          <Typography variant='h4' color='primary'>
                            Milloin opinnot ovat alkaneet?
                          </Typography>
                          <Grid container spacing={3} direction={{ xs: 'column', md: 'row' }}>
                            <Grid item xs={12}>
                              <TextField
                                {...register('study_start', { required: 'Pakollinen kenttä' })}
                                label='Opintojen aloitus'
                                type='date'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={e => setStudyStart(e.target.value)}
                                required
                                error={errors.study_start ? true : false}
                                helperText='Valitse päivämäärä'
                                style={{ marginBlockStart: '1em', marginBlockEnd: '1em' }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {page === 2 && student && (
                        <>
                          {!canApply ? (
                            <>
                              <p>
                                Et voi vielä hakea jäsenyyttä, mutta pääset antamaan meille tietosi,
                                jotta saat Fimnet-tunnukset ja Lääkärilehden. Anna tietosi täällä:
                              </p>
                              <LaakariButton
                                onClick={() => handleStudentFormClick()}
                                variant='contained'>
                                Opiskelijailmoittautuminen
                              </LaakariButton>
                            </>
                          ) : (
                            <PersonalInfo watch={watch} {...pageProps} />
                          )}
                        </>
                      )}

                      {page === 3 && (
                        <>
                          <Typography variant='h4' color='primary'>
                            Yhteenveto
                          </Typography>
                          <Summary formState={getValues()} {...pageProps} />
                        </>
                      )}
                    </ShadowContainer>

                    <Grid
                      container
                      spacing={3}
                      direction='row'
                      wrap='wrap-reverse'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ paddingTop: '3vh' }}>
                      {page === 0 && (
                        <Grid item xs={12} md={4}>
                          <FormButtonContainer align='left'>
                            <LaakariButton
                              variant='outlined'
                              onClick={() => navigate('/#membership')}>
                              Palaa etusivulle
                            </LaakariButton>
                          </FormButtonContainer>
                        </Grid>
                      )}

                      {page !== 0 && (
                        <Grid item xs={12} md={4}>
                          <FormButtonContainer align='left'>
                            <LaakariButton
                              variant='outlined'
                              disabled={page === 0}
                              onClick={handleBack}>
                              Takaisin
                            </LaakariButton>
                          </FormButtonContainer>
                        </Grid>
                      )}
                      {page !== 3 ? (
                        <Grid item xs={12} md={4}>
                          <FormButtonContainer align='right'>
                            <LaakariButton
                              onClick={handleNext}
                              disabled={page === 2 && student && !canApply}>
                              Jatka
                            </LaakariButton>
                          </FormButtonContainer>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={4}>
                          <FormButtonContainer align='right'>
                            <LaakariButton disabled={isSubmitting} type='submit'>
                              Lähetä
                            </LaakariButton>
                          </FormButtonContainer>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Fragment>
  ) : (
    <LinearProgress />
  )
}

export default StudentApplicationForm
