import React, { useState } from 'react'

import {
  Autocomplete,
  Grid,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider
} from '@mui/material'

import { Controller } from 'react-hook-form'
import {
  ErrorMessage,
  FormInput,
  FormInputWide,
  ResultsP,
  SubHeaderH3
} from '../../../components/styled/uiComponents'
import { CommonFormSectionProps } from '../../../types'
import { countryList, universityCountryList, universityList, yearList } from '../../../utils/utils'
import { userFoundInAapeli } from '../../../utils/membershipChecks'

const PersonalInfo: React.FC<CommonFormSectionProps & { watch: any; control: any }> = ({
  register,
  user,
  errors,
  control,
  metaData
}) => {
  const [cursusPresent, setCursusPresent] = useState<boolean>(false)

  const onChangeSchool = (value: any) => {
    setCursusPresent(value.length > 0)
  }

  return (
    <Grid container spacing={3} direction='row' justifyItems='flex-start' alignItems='stretch'>
      <Grid item xs={12}>
        <Typography variant='h4' color='primary'>
          Henkilötiedot
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Sukunimi</FormLabel>
        <p {...register('lastname')}>{user.lastname}</p>
        <ErrorMessage>{errors.lastname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Etunimet</FormLabel>
        <p {...register('firstname')}>{user.firstname}</p>
        <ErrorMessage>{errors.firstname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Kutsumanimi*</FormLabel>
        <FormInput defaultValue={user.nickname} {...register('nickname')} />
        <ErrorMessage>{errors.nickname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Henkilötunnus</FormLabel>
        <p {...register('els_hetu')}>{user.els_hetu}</p>
        <ErrorMessage>{errors.els_hetu?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <Typography variant='h4' color='primary'>
            Yhteystiedot
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoite*</FormLabel>
        <FormInputWide defaultValue={user.address1_line1} {...register('address.line1')} />
        <ErrorMessage>{errors.address?.line1?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoitteen rivi 2</FormLabel>
        <FormInputWide defaultValue={user.address1_line2} {...register('address.line2')} />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoitteen rivi 3</FormLabel>
        <FormInputWide defaultValue={user.address1_line3} {...register('address.line3')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Postinumero*</FormLabel>
        <FormInput defaultValue={user.address1_postalcode} {...register('address.postalcode')} />
        <ErrorMessage>{errors.address?.postalcode?.message}</ErrorMessage>
      </Grid>
      <Grid item md={2}></Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Postitoimipaikka*</FormLabel>
        <FormInput defaultValue={user.address1_city} {...register('address.city')} />
        <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postituskieli*</FormLabel>
        <Controller
          name='address.els_postikieli'
          control={control}
          defaultValue={
            JSON.stringify({
              attributevalue: user.postikieli?.attributevalue,
              value: user.postikieli?.value
            }) || ''
          }
          render={({ field: { onChange, value } }) => (
            <Select displayEmpty fullWidth onChange={e => onChange(e.target.value)} value={value}>
              <MenuItem value=''>-- Valitse --</MenuItem>
              {metaData.postikielet.map((mailLanguage: any, i: number) => (
                <MenuItem
                  key={i}
                  value={JSON.stringify({
                    attributevalue: mailLanguage.attributevalue,
                    value: mailLanguage.value
                  })}>
                  {mailLanguage.value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <ErrorMessage>{errors.address?.els_postikieli?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Maa*</FormLabel>
        <Controller
          name='address.country'
          control={control}
          defaultValue={
            JSON.stringify({
              els_koodiid: user.address1_country?.els_koodiid,
              els_koodinnimi: user.address1_country?.els_koodinnimi
            }) || ''
          }
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size='small'
              fullWidth
              disablePortal
              onChange={(e, val) => onChange(val)}
              value={value}
              disableClearable
              getOptionLabel={option =>
                option ? JSON.parse(option).els_koodinnimi : '-- Valitse --'
              }
              options={[
                '',
                ...countryList.map(country =>
                  JSON.stringify({
                    els_koodiid: country.els_koodiid,
                    els_koodinnimi: country.els_koodinnimi
                  })
                )
              ]}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          )}
        />
        <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Matkapuhelinnumero*</FormLabel>
        <FormInput defaultValue={user.mobilephone} {...register('mobilephone')} />
        <ErrorMessage>{errors.mobilephone?.message}</ErrorMessage>
      </Grid>
      <Grid item md={2}></Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Sähköpostiosoite*</FormLabel>
        <FormInput defaultValue={user.emailaddress1} {...register('emailaddress1')} />
        <ErrorMessage>{errors.emailaddress1?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <SubHeaderH3>Opiskelutiedot</SubHeaderH3>
        </div>
      </Grid>
      {!userFoundInAapeli(user) ? (
        <>
          <Grid item xs={12}>
            <FormLabel>Oppilaitos</FormLabel>
            <Controller
              name='cursus.els_oppilaitos'
              control={control}
              defaultValue={
                JSON.stringify({
                  els_koodiid: user.els_cursus?.els_oppilaitos?.els_koodiid,
                  els_koodinnimi: user.els_cursus?.els_oppilaitos?.els_koodinnimi
                }) || ''
              }
              render={({ field: { onChange, value } }) => (
                <Select
                  displayEmpty
                  fullWidth
                  onChange={e => {
                    onChange(e.target.value)
                    onChangeSchool(e.target.value)
                  }}
                  value={value}>
                  {universityList.map((school: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={JSON.stringify({
                        els_koodiid: school.els_koodiid,
                        els_koodinnimi: school.els_koodinnimi
                      })}>
                      {school.els_koodinnimi}
                    </MenuItem>
                  ))}
                  <Divider />
                  {universityCountryList.map((country: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={JSON.stringify({
                        els_koodiid: country.els_koodiid,
                        els_koodinnimi: country.els_koodinnimi
                      })}>
                      {country.els_koodinnimi}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <ErrorMessage>{errors.cursus?.els_oppilaitos?.message}</ErrorMessage>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <FormLabel>Aloitusvuosi</FormLabel>
                  <Controller
                    name='cursus.els_aloitusvuosi'
                    control={control}
                    defaultValue={user.els_cursus?.els_aloitusvuosi || ''}
                    render={({ field: { onChange, value } }) => (
                      <Select fullWidth onChange={e => onChange(e.target.value)} value={value}>
                        <MenuItem value=''>{'---Valitse---'}</MenuItem>
                        {yearList().map(year => (
                          <MenuItem value={`${year}`} key={year}>
                            {year.toString()}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <ErrorMessage>{errors.cursus?.els_aloitusvuosi?.message}</ErrorMessage>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <FormLabel>Lukukausi</FormLabel>
                  <Controller
                    name='cursus.els_lukukausi'
                    control={control}
                    defaultValue={
                      JSON.stringify({
                        attributevalue: user.els_cursus?.els_lukukausi?.attributevalue,
                        value: user.els_cursus?.els_lukukausi?.value
                      }) || ''
                    }
                    render={({ field: { onChange, value } }) => (
                      <Select
                        displayEmpty
                        fullWidth
                        onChange={e => onChange(e.target.value)}
                        value={value}>
                        <MenuItem value=''>{'---Valitse---'}</MenuItem>
                        {metaData.lukukaudet.map((semester: any, i: number) => (
                          <MenuItem
                            key={i}
                            value={JSON.stringify({
                              attributevalue: semester.attributevalue,
                              value: semester.value
                            })}>
                            {semester.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <ErrorMessage>{errors.cursus?.els_lukukausi?.message}</ErrorMessage>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <FormLabel>Oppilaitos</FormLabel>
            <ResultsP>{user.els_cursus.els_oppilaitos.els_koodinnimi}</ResultsP>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Aloitusvuosi</FormLabel>
            <ResultsP>{user.els_cursus.els_aloitusvuosi}</ResultsP>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Lukukausi</FormLabel>
            <ResultsP>{user.els_cursus.els_lukukausi.value}</ResultsP>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PersonalInfo
