export interface StampDetails {
  stampInfo: {
    name: string
    title: string
    terhikki: string
    quantity: number
    type: StampType
  }
  deliveryAddress: {
    name: string
    streetAddress: string
    postalCode: string
    city: string
    country: string
    mobilePhone: string
  }
}

export type StampKind = 'BACHELOR_STAMP' | 'LICENSED_PHYSICIAN_STAMP' | 'PAID_STAMP'

export type StampType = 'printer20' | 'goldring'
const defaultStampType: StampType = 'printer20'

export const stampTypeLongNames = {
  printer20: 'Printer 20 Green line pöytäleimasin (3 riviä), 17 euroa',
  goldring: 'Goldring Grandomatic kynäleimasin (3 riviä), 47 euroa'
}

export const generateDefaultStampDetails: (user: any, stampKind: StampKind) => StampDetails = (
  user: any,
  stampKind: StampKind
) => {
  const baseStampInfo = {
    name: `${user.firstname} ${user.lastname}`,
    terhikki: '',
    quantity: 1,
    type: defaultStampType
  }
  const deliveryAddress = {
    name: `${user.firstname} ${user.lastname}`,
    streetAddress: user.address1_line1,
    postalCode: user.address1_postalcode,
    city: user.address1_city,
    country: user.address1_country?.els_koodinnimi,
    mobilePhone: user.mobilephone
  }
  switch (stampKind) {
    case 'BACHELOR_STAMP':
      return {
        stampInfo: { ...baseStampInfo, title: 'Lääketieteen kandidaatti' },
        deliveryAddress
      }
    case 'LICENSED_PHYSICIAN_STAMP':
      return {
        stampInfo: { ...baseStampInfo, title: 'Laillistettu lääkäri' },
        deliveryAddress
      }
    case 'PAID_STAMP':
      return {
        isPaid: true,
        stampInfo: { ...baseStampInfo, title: '' },
        deliveryAddress
      }
    default:
      throw new Error('Unknown stamp type')
  }
}
